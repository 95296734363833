/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-06-24 09:50:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-07 10:01:30
 * @FilePath: \iview-admin-pro-template\src\api\dashboard\DashboardConsole.js
 */
import request from '@/plugins/request';

// 导出报表
export function downloadListReportRentHouseBigItemBySql(data) {
    return request({
        url: '/rentmanage/api/reportRentHouseBigItem/downloadListReportRentHouseBigItemBySql',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

export function listReportRentHouseBigItemBySql(data) {
    return request({
        url: '/rentmanage/api/reportRentHouseBigItem/listReportRentHouseBigItemBySql',
        method: 'post',
        data: data,
    });
}
// 到期未处理列表
export function listRentContractUnhandledByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentContractUnhandled/listRentContractUnhandledByAuthorizations',
        method: 'post',
        data: data,
    });
}
// 到期未处理列表-手工SQL
export function listRentContractUnhandledBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportListRentContractUnhandledBySQL',
        method: 'post',
        data: data,
    });
}

// 到期未处理列表-手工SQL
export function listRentContractUnhandledSettlementBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportListRentContractUnhandledSettementBySQL',
        method: 'post',
        data: data,
    });
}

// 续约到期提醒处理列表
export function listRentContractRenewalByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentContractRenewal/listRentContractRenewalByAuthorizations',
        method: 'post',
        data: data,
    });
}
//续约到期提醒处理列表-手工SQL
export function listRentContractRenewalBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportListRentContractRenewalBySQL',
        method: 'post',
        data: data,
    });
}
//续约到期提醒处理列表-手工SQL
export function listReportFinancialPaymentReminderBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportListReportFinancialPaymentReminderBySQL',
        method: 'post',
        data: data,
    });
}
//存房快到期提醒处理列表-手工SQL
export function listRentStorageBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportListDeputeContractBySQL',
        method: 'post',
        data: data,
    });
}

export function downloadFindHomeReportListRentContractUnhandledSettementBySQL(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/downloadFindHomeReportListRentContractUnhandledSettementBySQL',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

