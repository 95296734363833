/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-29T19:23:13.413
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportRentHouseBigItemBySql,
	downloadListReportRentHouseBigItemBySql,
	listRentContractUnhandledByAuthorizations,
	listRentContractUnhandledBySQL,
  listRentContractUnhandledSettlementBySQL,
  downloadFindHomeReportListRentContractUnhandledSettementBySQL,
	listRentContractRenewalByAuthorizations,
	listReportFinancialPaymentReminderBySQL,
	listRentStorageBySQL,
	listRentContractRenewalBySQL
} from '@api/dashboard/DashboardConsole';
import {
	listReportRentContractUndueByAuthorizations
} from '@api/report/ReportRentContractUndue'; 
import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
    downloadFindHomeReportListRentContractUnhandledSettementBySQL({
    	state
    }, obj) {
    	return new Promise((resolve, reject) => {
    		downloadFindHomeReportListRentContractUnhandledSettementBySQL(obj)
    			.then(async res => {
    				resolve(res);
    			})
    			.catch(err => {
    				reject(err);
    			})
    	})
    },
		// 欠费租客
		listReportFinancialPaymentReminderByAuthorizations({ state }, obj) {
			return new Promise((resolve, reject) => {
				listReportFinancialPaymentReminderByAuthorizations(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 欠费租客
		listReportFinancialPaymentReminderBySQL({ state }, obj) {
			return new Promise((resolve, reject) => {
				listReportFinancialPaymentReminderBySQL(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 到期未处理
		listRentContractUnhandledByAuthorizations({ state }, obj) {
			return new Promise((resolve, reject) => {
				listRentContractUnhandledByAuthorizations(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 存房快到期-手工sql
		listRentStorageBySQL({ state }, obj) {
			return new Promise((resolve, reject) => {
				listRentStorageBySQL(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 到期未处理-手工sql
		listRentContractUnhandledBySQL({ state }, obj) {
			return new Promise((resolve, reject) => {
				listRentContractUnhandledBySQL(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
    
    listRentContractUnhandledSettlementBySQL({ state }, obj) {
    	return new Promise((resolve, reject) => {
    		listRentContractUnhandledSettlementBySQL(obj)
    			.then(async res => {
    				resolve(res);
    			})
    			.catch(err => {
    				reject(err);
    			})
    	})
    },
    
		listRentContractRenewalByAuthorizations({ state }, obj) {
			return new Promise((resolve, reject) => {
				listRentContractRenewalByAuthorizations(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentContractRenewalBySQL({ state }, obj) {
			return new Promise((resolve, reject) => {
				listRentContractRenewalBySQL(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadListReportRentHouseBigItemBySql({
			state
		}) {
			return new Promise((resolve, reject) => {
				downloadListReportRentHouseBigItemBySql(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listReportRentHouseBigItemBySql({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentHouseBigItemBySql(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}

	}
};
